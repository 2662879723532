import cn from 'classnames';
import { FC, memo } from 'react';

import { ModulePlansAndPricingMembershipPlan } from '@components/common/types/ModulePlansAndPricing';
import Button from '@components/ui/Button/Button';
import Text from '@components/ui/Text/Text';

import styles from './planCard.module.scss';

export enum MembershipPeriodOptions {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
}

type Props = Omit<ModulePlansAndPricingMembershipPlan, 'internalName'> & {
  period: MembershipPeriodOptions;
  className: string;
};

const PlanCardv1: FC<Props> = ({
  badge,
  heading,
  monthlyPrice,
  yearlyPrice,
  yearlySave,
  benefitsTitle,
  benefitsList,
  benefitsDescription,
  ctaText,
  ctaOpenNewTab,
  annualCtaLink,
  monthlyCtaLink,
  period,
  className,
}) => {
  const price = period === MembershipPeriodOptions.Yearly ? yearlyPrice : monthlyPrice;
  const link = period === MembershipPeriodOptions.Yearly ? annualCtaLink : monthlyCtaLink;

  return (
    <div className={cn(className, styles.planCard, badge && ' !bg-purple border-4 border-purple')}>
      {/* mostPopularBadge */}
      {badge && <span className="caption text-sm md:text-[14px] text-white text-center my-4 font-bold">{badge}</span>}
      <div className={cn(styles.planContainer, badge && 'h-full')}>
        {heading && <h3 className={styles.heading}>{heading}</h3>}
        <div className="flex items-center gap-3 mb-5">
          {price && <h4 className={styles.price}>{price}</h4>}
          {yearlySave && period === MembershipPeriodOptions.Yearly && (
            <span className="bg-[#c8e802] px-1 text-sm">{yearlySave}</span>
          )}
        </div>
        {link && ctaText && (
          <Button
            variant="cta"
            href={link.toString()}
            className="!rounded-[12px] !text-[18px] mb-6 w-full"
            target={ctaOpenNewTab ? '_blank' : '_self'}
          >
            {ctaText}
          </Button>
        )}
        {benefitsDescription && (
          <Text asElement="div" html={benefitsDescription} className={styles.benfittsDescriptionv1} />
        )}
        {benefitsTitle && <p className="font-bold mb-4 text-[16px] md:text-[18px]">{benefitsTitle}</p>}
        {benefitsList && <Text asElement="div" html={benefitsList} className={styles.benefits} />}
      </div>
    </div>
  );
};

export default memo(PlanCardv1);
