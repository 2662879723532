import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { ModulePlansAndPricingV1 as Props } from '@components/common/types/ModulePlansAndPricing';
import { Renderer } from '@components/screen/factory';
import Button from '@components/ui/Button/Button';
import Section from '@components/ui/Section/Section';
import getTailwindConfig from '@lib/get-tailwind-config';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';

import PlanCardV1, { MembershipPeriodOptions } from './PlanCard';
import PlanToggle from './PlanToggle';

import style from './planCard.module.scss';

const PlansAndPricing: FC<Props> = ({
  period,
  defaultPeriod,
  membershipPlan,
  mobileDefaultCardView,
  header,
  description,
  footerButtonTitle,
  buttonRedirectUrl,
}) => {
  const [membershipPeriod, setMembershipPeriod] = useState<MembershipPeriodOptions>(
    defaultPeriod ?? MembershipPeriodOptions.Yearly
  );
  const lg = getTailwindConfig('theme.screens.sm');
  const isMobileDevice = useMatchMedia(lg);
  const swiperRef = useRef<SwiperRef>(null);

  const planToggleListener = () => {
    setMembershipPeriod((prevPeriod) =>
      prevPeriod === MembershipPeriodOptions.Yearly ? MembershipPeriodOptions.Monthly : MembershipPeriodOptions.Yearly
    );
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current?.swiper?.slideTo((mobileDefaultCardView || 1) - 1 || 0);
    }
  }, [mobileDefaultCardView]);

  return (
    <Section mobileNoPadding>
      <div className="flex flex-col items-center ">
        <div className="w-full p-4 md:max-w-6xl mx-auto">
          <h1 className="text-center font-black text-5xl leading-12 md:text-8xl md:leading-26 mb-4">{header}</h1>
          <h5 className="text-center font-bold text-xl leading-6 md:text-[2.25rem] md:leading-10">{description}</h5>
        </div>
        <PlanToggle options={period} period={membershipPeriod} onChange={planToggleListener} />
        {isMobileDevice ? (
          <Swiper
            className="pt-10 md:pt-20 pb-10 md:py-10 w-full"
            modules={[FreeMode, Pagination]}
            setWrapperSize
            freeMode
            centeredSlides
            slidesPerView={1.5}
            spaceBetween={30}
            breakpoints={{
              480: {
                slidesPerView: 1.5,
              },
              540: {
                slidesPerView: 1.85,
              },
              640: {
                slidesPerView: 1.75,
              },
              720: {
                slidesPerView: 2,
              },
            }}
            ref={swiperRef}
          >
            {membershipPlan.map(({ content, id }, i) => (
              <SwiperSlide key={`${id + i}`} className="h-auto !w-[345px] mr-2 !-ml-12">
                <PlanCardV1 {...content} period={membershipPeriod} className="h-full m-auto" />
              </SwiperSlide>
            ))}

            <div
              className={cn(
                style.swiperPagination,
                membershipPlan.length <= 2 ? style.swiperPaginationTooFewSlides : '',
                'featured-slide-pagination'
              )}
            />
          </Swiper>
        ) : (
          <div className="flex flex-row my-8 gap-2 md:gap-4">
            {membershipPlan.map(({ content, id }, i) => (
              <PlanCardV1 key={`${id + i}`} {...content} period={membershipPeriod} className="mx-4" />
            ))}
          </div>
        )}
        <Button href={buttonRedirectUrl} className="!rounded-xl">
          {footerButtonTitle}
        </Button>
      </div>
    </Section>
  );
};

export default Renderer({ name: 'modulePlansAndPricingV1' })(PlansAndPricing);
