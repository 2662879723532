import { useTranslation } from 'next-i18next';
import { FC } from 'react';

type Props = { options: string[]; period?: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void };

const PlanToggle: FC<Props> = ({ options, period, onChange }) => {
  const { t } = useTranslation(['plans']);

  return (
    <div className="flex justify-center items-center gap-3">
      <span className="text-lg leading-[130%] text-[#51504B]">{options[0]}</span>
      <div className="flex justify-center items-center">
        <input id="plan_switch" type="checkbox" className="hidden peer" onChange={onChange} />
        <label
          htmlFor="plan_switch"
          className={`block w-[80px] h-[44px] rounded-full  transition-all duration-400 cursor-pointer relative  ${period === 'Yearly' ? 'bg-[#c8e802]' : 'bg-[#51504B]'}`}
          title={t('plans:choosePlans.planToggle.label.title')}
          aria-label="Toggle switch"
        >
          <span
            className={`absolute w-[35px] h-[35px] bg-white rounded-full transition-all duration-400 top-1/2 transform -translate-y-1/2   ${period === 'Yearly' ? 'left-[40px] ' : ' left-[4px]'}`}
          ></span>
        </label>
      </div>
      <span className="text-lg  leading-[130%] text-[#51504B]">{options[1]}</span>
    </div>
  );
};

export default PlanToggle;
